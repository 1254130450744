<template>
  <div
      class="vue2-scale-box"
      ref="vue2ScaleBox"
      :style="{
      ...style,
      width: width + 'px',
      // height: height + 'px',
      backgroundColor: bgc,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
/**
 * width        宽
 * height       高
 * bgc          背景颜色
 * delay        自适应缩放防抖延迟时间（ms）
 * @scaleChange 缩放值发生改变的方法 可动态获取 scale 改变后的值
 */
export default {
  name: "Vue2ScaleBox",
  props: {
    width: {
      type: Number,
      default: 1920,
    },
    // height: {
    //     type: Number,
    //     default: 1080,
    // },
    bgc: {
      type: String,
      default: "transparent",
    },
    delay: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      scale: 0,
      style: {
        position: "relative",
        transform: "scale(var(--scale))",
        transformOrigin: "0 0",
        zIndex: 2,
        height: 'var(--height)',
      },
      height: window.innerHeight, // 屏幕高度
      devicePixelRatio: window.devicePixelRatio,
    };
  },
  watch: {
    scale: {
      handler(scale) {
        this.$emit("scaleChange", scale);
      },
      immediate: true,
    },
  },
  mounted() {
    this.setScale();
    window.addEventListener("resize", this.debounce(this.setScale));
  },
  methods: {
    getScale() {
      const {width, height} = this;
      // const wh = window.innerHeight * window.devicePixelRatio / height;
      const ww = (window.innerWidth - 20) / width;
      // console.log('getScale', ww, wh, width, height, window.innerWidth, window.innerHeight)
      return ww;
    },
    setScale() {
      this.scale = this.getScale();
      if (this.$refs.vue2ScaleBox) {
        this.$refs.vue2ScaleBox.style.setProperty("--scale", this.scale);
        this.$refs.vue2ScaleBox.style.setProperty("--height", (this.height * this.scale) + 'px');
        // this.$refs.vue2ScaleBox.style.height = `${this.height * this.scale}px`;
      }
    },
    debounce(fn, delay) {
      const delays = delay || this.delay;
      let timer;
      return function () {
        const th = this;
        const args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          timer = null;
          fn.apply(th, args);
        }, delays);
      };
    },
  },
};
</script>
