import request from '@/utils/request'

// 公司简介
export function companyIntroduce(params ){
    return request({
        url:'/system/profile/list',
        method:'GET',
        params
    })
}


// 发展历程
export function developmentHistory(params ){
    return request({
        url:'/system/history/list',
        method:'GET',
        params
    })
}

// 专家团队
export function experts(params ){
    return request({
        url:'/system/expert/list',
        method:'GET',
        params
    })
}

// 服务优势
export function serviceAdvantage(params ){
    return request({
        url:'/system/profile/list',
        method:'GET',
        params
    })
}

// 办公地点
export function officeLocation(params){
    return request({
        url:'/home/getAboutUs',
        method:'POST',
        params
    })
}

// 图片类型
export function byTypeGetImg(){
    return request({
        url:'/home/getPicTypes',
        method:'POST',
    })
}

// 图片列表 
export function allPicList(){
    return request({
        url:'/home/getHomeComProfile',
        method:'POST',
    })
}














