import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import BaiduMap from '@/components/vue-baidu-map'
//全局注册百度地图
Vue.use(BaiduMap, {
  ak: 'pOkFyQdPN0Y3AUtID2OzzqtqNDk3uRtn'
})

// 引入字体
import '@/assets/font/font.css';
import '@/assets/css/common.less';

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
