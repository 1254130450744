<template>
    <div class="banner-wrap">
        <el-carousel height="540px" trigger="click" :interval="5000" arrow="never" class="carousel">
            <el-carousel-item class="carousel-item">
                <img src="@/assets/images/banner/首页banner1_20230629152246A088.jpg" />
            </el-carousel-item>
            <el-carousel-item class="carousel-item">
                <img src="@/assets/images/banner/首页banner2_20230407092230A003.jpg" />
            </el-carousel-item>
<!--            <el-carousel-item class="carousel-item">-->
<!--                <img src="@/assets/images/banner/首页 banner3_20230407093015A007.jpg" />-->
<!--            </el-carousel-item>-->
        </el-carousel>
    </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {
    }
  },
  watch:{
  },
  created () {
  },
  methods: {
  },
}
</script>
<style lang="less" scoped>
    .banner-wrap{
        width: 1920px;
        // margin-top: 64px;
        .carousel-item{
            img{
                width:100%;
                height:100%;
                object-fit: cover;
            }
        }

        /deep/ .el-carousel__indicators {// 指示器
            bottom:10%;
            transform: unset;
            left: 18.5%;
            .el-carousel__button{
                width:40px;
                height:4px;
                background-color:rgba(81, 92, 102, 0.2);
                border-radius: 60px;
            }
        }
        /deep/ .el-carousel__indicators .is-active{// 指示器
            .el-carousel__button{
                width:40px;
                height:4px;
                background-color:rgba(81, 92, 102, 1);
                border-radius: 60px;
            }
        }
    }

</style>
