import request from "@/utils/request"


// 解决方案-应用案例列表
export function CaseList(params){
    return request({
        url:'/system/case/list',
        method:'GET',
        params
    })
}

// 解决方案列表
export function SolutionList(data){
    return request({
        url:'/system/solution/list',
        method:'GET',
        data
    })
}

// 解决方案 重点推荐
export function highlyRecommended(){
    return request({
        url:'/system/solution/recommend',
        method:'GET',
    })
}


// 产品分类列表（硬件、软件）
export function productList(params){
    return request({
        url:'/system/type/getHomeProductType',
        method:'GET',
        params
    })
}


// 动态咨询
export function dynamicInfo(params){
    return request({
        url:'/home/getHomeNewsList',
        method:'GET',
        params
    })
}










