import request from "@/utils/request";


// 产品列表
export function productDetails(id){
    return request({
        url:'/system/product/'+id,
        method:'GET',
    })
}

// 推荐产品
export function recommendProduct(id){
    return request({
        url:'system/product/getRecommend/'+id,
        method:'GET',
    })
}

// 分类产品
export function typeProductList(){
    return request({
        url:'system/product/typeDataList',
        method:'GET',
    })
}




