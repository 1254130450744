<template>
    <div class="productDetails">
        <div class="product"  v-for="(item,index) in productList" :key="index" :style="{backgroundColor:item.background?item.background:''}">
            <div :id="'title' + index" class="topTitle">
                <div class="equipName" >{{ item.proCharName }}</div>
                <div class="characteristic" >{{ item.proDesc }}</div>
            </div>

            <div
                :id="'scroll'+index"
                class="soilEquipBg"
                :style="{
                    maxWidth:index == 0?'1920px':'1220px',
                }">
                <img :src="baseURL+item.pic" alt="">
            </div>
            <!-- <div
                :id="'scroll'+index"
                class="soilEquipBg"
                :style="{
                    backgroundImage:`url(${baseURL}${item.pic})`,
                    maxWidth:index == 0?'1920px':'1220px',
                }">
            </div> -->
        </div>

        <!-- <div class="recommendProducts" ref="recommendProducts" v-if="recommendProductList&&recommendProductList.length > 2">
            <div class="recommendProducts_head" :class="{active : isScroll }">
                <div>推荐产品</div>
                <div>为了更好地产品体验，我们为您精选了相关产品</div>
            </div>
            <div class="recommendProducts_content wrap" :class="{active : isScroll }">
                <div class="recommendProducts_content_left">
                    <div v-if="recommendProductList[0]">
                        <div class="equipName">{{ recommendProductList[0].productName}}</div>
                        <div class="viewDetails" @click="pageJumps(`/ProductDetails?id=${recommendProductList[0].id}`)">
                            查看详情
                            <i class="el-icon-d-arrow-right"></i>
                        </div>
                    </div>
                    <img v-if="recommendProductList[0]" :src="baseURL+recommendProductList[0].productPic" alt="">
                </div>
                <div class="recommendProducts_content_right">
                    <div>
                        <div v-if="recommendProductList[1]">
                            <div class="equipName">{{ recommendProductList[1].productName}}</div>
                            <div class="viewDetails"  @click="pageJumps(`/ProductDetails?id=${recommendProductList[1].id}`)">
                                查看详情
                                <i class="el-icon-d-arrow-right"></i>
                            </div>
                        </div>
                        <img v-if="recommendProductList[1]" :src="baseURL+recommendProductList[1].productPic" alt="">
                    </div>
                    <div>
                        <div v-if="recommendProductList[2]">
                            <div class="equipName">{{ recommendProductList[2].productName}}</div>
                            <div class="viewDetails"  @click="pageJumps(`/ProductDetails?id=${recommendProductList[2].id}`)">
                                查看详情
                                <i class="el-icon-d-arrow-right"></i>
                            </div>
                        </div>
                        <img v-if="recommendProductList[2]" :src="baseURL+recommendProductList[2].productPic" alt="">
                    </div>
                </div>
            </div>
        </div> -->
        <contactUs style="width:100%" />
    </div>
</template>

<script>
import {productDetails,recommendProduct} from '@/api/product'
import {productList} from '@/api/header'
import contactUs from '@/components/contactUs.vue'
import {baseURL} from '@/utils/request'
    export default {
        components:{
            contactUs,
        },
        data(){
            return{
                baseURL:baseURL,
                productList:[
                    {
                        backgroundColor:'rgba(240, 244, 245, 1)',
                        title1:'精准土壤墒情监测仪',
                        title2:'高效完成自动化监测',
                        backgroundImg:require('@/assets/images/product/soil1.png'),
                    },
                    {
                        backgroundColor:'rgba(32, 36, 37, 1)',
                        title1:'',
                        title2:'',
                        backgroundImg:require('@/assets/images/product/soil2.png'),
                    },
                    {
                        backgroundColor:'rgba(255, 255, 255, 1)',
                        title1:'防水防尘，无惧户外复杂环境',
                        title2:'全身防水防尘设计，不怕风吹雨打，适应室内室外各种复杂环境。',
                        backgroundImg:require('@/assets/images/product/soil3.png'),
                    },
                    {
                        backgroundColor:'rgba(240, 244, 245, 1)',
                        title1:'数据实时发送，云端查看一目了然',
                        title2:'精准土壤墒情检测仪可实时发送数据到云端后台系统，经过系统智能识别解析，当诊断出指标出现异常状态时，可及时给用户发送预警消息，并提供相应的植保方案。',
                        backgroundImg:require('@/assets/images/product/soil4.png'),
                    },
                    {
                        backgroundColor:'rgba(255, 255, 255, 1)',
                        title1:'太阳能供电，4G网络传输，无需布线',
                        title2:'太阳能电池板供电，无需额外拉电线，部署更灵活；100mW低功耗超长续航，连续阴雨30天也能维持设备正常运转。4G网络传输，无需额外搭建有线网络。',
                        backgroundImg:require('@/assets/images/product/soil5.png'),
                    },
                ],
                scrollTop:0,
                isScroll:false,
                id:null,
                type:null,
                recommendProductList:[],
            }
        },
        watch:{
            '$route'(newValue){
                this.id = this.$route.query.id
                this.type = this.$route.query.type
                this.getproductDetails()
                this.getRecommendProduct()
            }
        },
        created(){
            // console.log("this.baseURL",this.baseURL);
            this.id = this.$route.query.id
            this.type = this.$route.query.type
            this.getproductDetails()
            this.getRecommendProduct()
        },
        mounted(){
            window.addEventListener('scroll',() => {
                this.scrollTop = document.documentElement.scrollTop / this.$store.state.scale
                // // console.log("this.scrollTop",this.scrollTop);
                if(this.$refs.recommendProducts&&this.scrollTop >= this.$refs.recommendProducts.getBoundingClientRect().top){
                    this.isScroll = true
                }
                this.productList.forEach((val,index) => {
                    if(val.scrollTop && this.scrollTop >= val.scrollTop){
                        if(document.querySelector(`#scroll${index}`) && !document.querySelector(`#scroll${index}`).className.includes('active')){
                            document.querySelector(`#scroll${index}`).className = 'soilEquipBg active'
                        }
                        if(document.querySelector(`#title${index}`)){
                            if( !document.querySelector(`#title${index}`).className.includes('active')){
                                document.querySelector(`#title${index}`).className = 'topTitle active'
                            }
                        }
                        // // console.log("document.querySelector(`#scroll${index}`).className",document.querySelector(`#scroll${index}`).className);
                    }
                })
            })
        },
        methods:{
            pageJumps(path){
                this.$router.push({path})
            },
            getproductDetails(){
                this.show=false
                productDetails(this.id).then(res=>{
                    console.log("res 产品详情",res);
                    if(res.code == 200){
                        let topBanner = {
                            background:'',
                            proCharName:res.data.productName,
                            proDesc:res.data.introduce,
                            pic:res.data.productBanner
                        }

                        this.productList = [topBanner,...res.data.productCharacters]
                        this.productList.forEach((val,index) => {
                            if(index == 0){
                                val.scrollTop = 0
                            }else if(index == 1){
                                val.scrollTop = 10
                            }else{
                                val.scrollTop = (index - 1) * 850 + 100
                            }
                        })
                        console.log("this.productList",this.productList);
                    }
                })
            },
            getProductList(){
                productList({id:1}).then(res => {
                    // console.log("硬件设备列表",res);
                    if(res.code == 200){
                        res.data.forEach(val => {
                            this.recommendProductList.push(...val.productList)
                        })
                    }
                })
            },
            // 获取推荐产品
            getRecommendProduct(){
                recommendProduct(this.type).then(res => {
                    // console.log("res 推荐产品",res);
                    if(res.code == 200){
                        this.recommendProductList = res.rows
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
.productDetails{
    // padding: 64px 0 0 0;
    .product{
        height: 760px;
        padding: 45px 0 0 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
        .topTitle{
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            z-index: 2;
            .equipName{
                font-size: 48px;
                color: rgba(31, 35, 41, 1);
            }
            .characteristic{
                width: 1000px;
                text-align: center;
                font-size: 16px;
                color: rgba(31, 35, 41, .8);
                margin: 24px 0 0 0;
            }
        }
        .soilEquipBg{
            width: 100%;
            height: 100%;
            background-size: 100% 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            >img{
                width: 100%;
                height: 100%;
                // object-fit: cover;
            }
        }
    }
    .product:nth-child(1){
        .equipName{
            font-weight: bold;
        }
    }
    .product:not(:nth-child(1)){
        height: 860px;
        .topTitle{
            opacity: 0;
            transform: translateY(200%);
            transition: all 1s;
            &.active{
                opacity: 1;
                transform: translateY(0%);
            }
        }
        .soilEquipBg{
            // opacity: 0;
            transform: translate(-50%,100%);
            transition: all 1s;
            &.active{
                opacity: 1;
                transform: translate(-50%,0%);
            }
        }
    }

    .recommendProducts{
        padding: 100px 0 102px 0;
        background: rgba(32, 36, 37, 1);
        overflow: hidden;
        .recommendProducts_head{
            display: flex;
            flex-direction: column;
            align-items: center;
            transform: translateY(200%);
            opacity: 0;
            transition: all 1s;
            >div:nth-child(1){
                font-size: 36px;
                color: rgba(255, 255, 255, 1);
            }
            >div:nth-child(2){
                font-size: 16px;
                color: rgba(255, 255, 255, .8);
                margin: 16px 0 44px 0;
            }
            &.active{
                opacity: 1;
                transform: translateY(0%);
            }
        }
        .recommendProducts_content{
            display: flex;
            transform: translateY(100%);
            opacity: 0;
            transition: all 1s;
            &.active{
                opacity: 1;
                transform: translateY(0%);
            }
            .recommendProducts_content_left{
                width: 590px;
                height: 680px;
                border-radius: 12px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 24px 0px rgba(32, 37, 45, 0.06);
                margin: 0 40px 0 0;
                padding: 44px;
                box-sizing: border-box;
                display: flex;
                >div{
                    .equipName{
                        font-size: 32px;
                        color: rgba(31, 35, 41, 1);
                        width: 160px;
                        margin: 0 0 18px 0;
                    }
                    .viewDetails{
                        cursor: pointer;
                        color: rgba(43, 130, 229, 1);
                        font-size: 16px;
                    }
                }
                img{
                    width: 300px;
                    margin: 0 0 0 20px;
                }
            }
            .recommendProducts_content_right{
                >div{
                    width: 590px;
                    height: 320px;
                    border-radius: 12px;
                    background: rgba(255, 255, 255, 1);
                    box-shadow: 0px 0px 24px 0px rgba(32, 37, 45, 0.06);
                    display: flex;
                    justify-content: space-between;
                    padding: 40px;
                    box-sizing: border-box;
                    >div{
                        .equipName{
                            font-size: 32px;
                            color: rgba(31, 35, 41, 1);
                            width: 160px;
                            margin: 0 0 18px 0;
                        }
                        .viewDetails{
                            color: rgba(43, 130, 229, 1);
                            font-size: 16px;
                            cursor: pointer;
                        }
                    }
                    img{
                        width: 120px;
                    }
                }
                >div:nth-child(1){
                    margin: 0 0 40px 0;
                }
            }
        }
    }
}
</style>
