<template>
  <div class="product wrap" ref="container">
    <!-- 好物推荐 -->
    <div class="recommend">
      <div class="title">重点推荐</div>
      <div class="recommend-list">
        <div class="recommend-list-item" v-for="(item,index) in recommendList" :key="index" :id="'recommend'+index">
          <div class="pic">
            <img :src="baseURL+item.standardPic" alt="">
          </div>
          <div class="content">
            <div class="name">{{ item.productName }}</div>
            <div class="feature">{{ item.introduce }}</div>
            <div class="look-btn" @click="pageJumps(`/ProductDetails?id=${item.id}`)">查看介绍</div>
          </div>
        </div>
      </div>
    </div>

    <div class="productSeries" :id="'series'+index" :class="{active : nape.isScroll }"
         v-for="(nape,index) in typeProductList" :key="index" ref="nameBox" :dataName="nape.name">
      <div class="title">{{ nape.name }}</div>
      <div v-for="(val,k) in nape.list" :key="k">
        <div class="special-product" v-if="val.flagFirst==1">
          <div class="special-product-left">
            <img :src="baseURL+val.standardPic" alt="">
            <div class="intro">
              <div class="name">{{ val.productName }}</div>
              <div class="feature">{{ val.introduce }}</div>
              <div class="look-btn" @click="pageJumps(`/ProductDetails?id=${val.id}`)">查看介绍</div>
            </div>
          </div>
          <div class="special-product-right">
            <img :src="baseURL+val.imgDisplay" alt="">
          </div>
        </div>
      </div>

      <div class="otherProduct">
        <el-carousel height="400px" :autoplay="false" arrow="never" indicator-position="none" :id="'list'+index"
                     :ref="'carousel'+index">
          <el-carousel-item v-for="k in Math.ceil(nape.list.length / 4)" :key="k">
            <div class="product-list">
              <div class="product-list-item" v-for="(item,i) in nape.list.slice((k - 1) * 4, k * 4)" :key="i"
                   @click="pageJumps(`/ProductDetails?id=${item.id}`)">
                <div class="pic">
                  <img :src="baseURL+item.standardPic" alt="">
                </div>
                <div class="name">{{ item.productName }}</div>
                <div class="intro">{{ item.subtitle }}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <div class="btn" v-if="nape.list.length>4">
          <button class="pre-btn" @click="prev(`carousel${index}`)"><i class="el-icon-arrow-left"></i></button>
          <button class="next-btn" @click="next(`carousel${index}`)"><i class="el-icon-arrow-right"></i></button>
        </div>
      </div>

      <!-- <div class="otherProduct">
          <div class="product-list" :id="'list'+index"
              :style="{
                  'margin-left':`${nape.distance}px`,
                  'transition':'0.5s',
              }">
              <div class="product-list-item" v-for="(item,i) in nape.list" :key="i" @click="pageJumps(`/ProductDetails?id=${item.id}`)">
                  <div class="pic">
                      <img :src="baseURL+item.standardPic" alt="">
                  </div>
                  <div class="name">{{item.productName}}</div>
                  <div class="intro">{{item.subtitle}}</div>
              </div>
          </div>


          <div class="btn">
              <button class="pre-btn"  @click="prev(index,nape)"><i class="el-icon-arrow-left"></i></button>
              <button class="next-btn"  @click="next(index,nape)"><i class="el-icon-arrow-right"></i></button>
          </div>
      </div> -->
    </div>

  </div>
</template>
<script>
import {baseURL} from "@/utils/request"
import {productList} from '@/api/footer'
import {typeProductList} from "@/api/product"

export default {
  name: '',
  data() {
    return {
      baseURL,
      recommendList: [],
      typeProductList: [],
      screenWidth: null,
      type: '',
      num: 0,
    }
  },
  computed: {
    scale() {
      return this.$store.state.scale
    }
  },
  watch: {
    '$route': { // $route可以用引号，也可以不用引号
      handler(to, from) {
        // console.log('路由变化了')
        this.type = this.$route.query.type
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
    type(newValue, oldValue) {
      if (newValue || oldValue) {
        if (this.num == 2) {
          this.scrollFn()
        }
      }
    },

    num(newValue) {
      if (newValue == 2) {
        this.debounceScrollFn2()
      }
    },
  },
  created() {
    this.debounceScrollFn2 = this.debounce(this.scrollFn2, 100)
    this.getProductList()
    this.getTypeList()
  },

  updated() {
    this.calcWidth()
  },
  mounted() {
    this.calcWidth()

    window.addEventListener('scroll', () => {
      this.scrollTop = document.documentElement.scrollTop / this.$store.state.scale
      // console.log("this.scrollTop",this.scrollTop);
      this.typeProductList.forEach((item, idx) => {
        if (document.querySelector(`#series${idx}`) && this.scrollTop >= document.querySelector(`#series${idx}`).getBoundingClientRect().top) {
          item.isScroll = true
        }
      })

      this.recommendList.forEach((val, index) => {
        if (val.scrollTop && this.scrollTop >= val.scrollTop) {
          if (document.querySelector(`#recommend${index}`) && !document.querySelector(`#recommend${index}`).className.includes('dynamic')) {
            document.querySelector(`#recommend${index}`).classList.add('dynamic')
          }
        }
      })

    })

  },
  methods: {
    // 计算列表数据宽度
    calcWidth() {
      this.$nextTick(() => {
        this.typeProductList.forEach((item, idx) => {
          item.listWidth = document.querySelector(`#list${idx}`).scrollWidth
        })
      })
    },

    // 获取推荐产品
    getProductList() {
      let para = {
        pageNum: 1,
        pageSize: 999,
      }
      productList(para).then(res => {
        console.log(res, "产品列表");
        if (res.code == 200) {
          this.num += 1
          let productList = res.rows
          productList.forEach(item => {
            if (item.recommend == 1) {
              this.recommendList.push(item)
            }
          })

          this.recommendList.forEach((val, index) => {
            if (index == 0) {
              val.scrollTop = 0
            } else if (index == 1) {
              val.scrollTop = 10
            } else {
              val.scrollTop = (index - 1) * 400 + 100
            }
          })
        }
      })
    },

    // 获取产品分类列表
    getTypeList() {
      typeProductList().then(res => {
        this.num += 1
        let data = res.data
        for (var key in data) {
          this.typeProductList.push(
              {name: key, list: data[key], isScroll: false, distance: 0, listWidth: null}
          );
        }
        console.log(this.typeProductList, "分类产品数据")
      })
    },

    prev(refName) {
      this.$nextTick(() => {
        this.$refs[refName][0].prev()
      })
    },

    next(refName) {
      this.$nextTick(() => {
        this.$refs[refName][0].next()
      })
    },

    // prev(index,nape){
    //     let width = nape.distance + document.querySelector(`#list${index}`).offsetWidth
    //     let offsetLeft=document.querySelector(`#list${index}`).offsetLeft

    //     if(offsetLeft!=0){
    //         if(width < nape.listWidth){
    //             this.typeProductList[index].distance+=310
    //         } else{
    //             this.typeProductList[index].distance=0
    //         }
    //     }
    // },

    // next(index,nape){
    //     let width = document.querySelector(`#list${index}`).offsetWidth
    //     if(width < nape.listWidth){
    //         this.typeProductList[index].distance=-(Math.abs(this.typeProductList[index].distance)+310)
    //     } else{
    //         // this.distance=0
    //     }
    // },

    pageJumps(path) {
      this.$router.push({path}, () => {
      })
    },

    scrollFn() {
      console.log(this.scale, "mmmmmmmmmmmmmmm")
      this.$nextTick(() => {
        let list = this.$refs.nameBox
        if (list) {
          list.forEach((item, index) => {
            if (item.getAttribute('dataname') == this.type) {
              // let scrollTop = item.getBoundingClientRect()
              // console.log(scrollTop, "scrollTopscrollTopscrollTopscrollTop")
              // setTimeout(() => {
              // document.documentElement.scrollTop = scrollTop.top - 200 * this.scale
              item.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
              })
              console.log('scrollTop', document.documentElement.scrollTop)
              // }, 0)
            }
          })
        }
      })
    },
    // 防抖函數
    debounce(fn, delay) {
      let timer = null
      return function () {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(fn, delay)
      }
    },
    scrollFn2() {
      this.$nextTick(() => {
        console.log(this.scale, "scrollFn2", this.$refs.nameBox)
        let list = this.$refs.nameBox
        if (list) {
          list.forEach((item, index) => {
            if (item.getAttribute('dataname') == this.type) {
              // console.log("scrollFn2", item.getAttribute('dataName'))
              // let scrollTop = item.getBoundingClientRect()
              // console.log(scrollTop, "scrollTopscrollTopscrollTopscrollTop")
              // setTimeout(() => {
              //   92*index  头部固定高度
              //   72*(index+1)  每个元素的margin-top值
              // console.log(scrollTop.top, "scrollTop.scrollTop.scrollTop.scrollTop")
              // console.log('1', document.documentElement.scrollTop)
              // console.log(scrollTop.top - this.scale * 200 - (92 * index * this.scale) - 72 * (index + 1) * this.scale, "scrollTop.scrollTop.scrollTop.scrollTop")
              // // document.documentElement.scrollTop = scrollTop.top - this.scale * 200 - (92 * index * this.scale) - 72 * (index + 1) * this.scale
              // document.documentElement.scrollTop = scrollTop.top * this.scale;
              item.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
              })
              // }, 0)
            }
          })
        }
      })

    },

  },
}
</script>
<style lang="less" scoped>
.product {
  padding: 64px 0 0 0;
  position: relative; // 关键
  .recommend {
    margin-top: 72px;

    .title {
      font-size: 36px;

      font-weight: 500;
      color: rgba(31, 35, 41, 1);
    }

    .recommend-list {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .recommend-list-item {
        height: 460px;
        border-radius: 4px;
        background: rgba(230, 236, 237, 1);
        display: flex;
        // justify-content: space-around;
        align-items: center;
        // gap: 20px;
        transform: translateY(10%);
        opacity: 0;

        &:nth-child(1) {
          transform: translateY(0);
          opacity: 1;
        }

        &:nth-child(2n) {
          flex-direction: row-reverse;

          .content {
            padding: 20px 65px 20px 160px;
          }
        }

        .pic {
          width: 580px;
          height: 100%;
          padding: 20px 136px;
          box-sizing: border-box;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .content {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 20px 104px 20px 200px;

          .name {
            font-size: 40px;
            font-weight: 500;
            color: rgba(31, 35, 41, 1);
          }

          .feature {
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            span {
              font-size: 24px;
              color: rgba(31, 35, 41, 0.8);
            }
          }

          .look-btn {
            margin-top: 25px;
            width: 183px;
            height: 52px;
            border-radius: 8px;
            border: 1px solid rgba(33, 150, 72, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 500;
            line-height: 32px;
            color: rgba(33, 150, 72, 1);
            cursor: pointer;
          }
        }
      }

      .dynamic {
        opacity: 1;
        transform: translateY(0);
        transition: all 1s;
      }
    }

  }

  .productSeries {
    // margin-top: 72px;
    margin-top: 200px;
    opacity: 0;

    &:last-child {
      margin-bottom: 100px;
    }

    .title {
      font-size: 36px;
      font-weight: 500;
      color: rgba(31, 35, 41, 1);
    }

    .special-product {
      margin-top: 30px;
      height: 410px;
      border-radius: 4px;
      overflow: hidden;
      background: rgba(255, 255, 255, 1);
      display: flex;

      .special-product-left {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 50px 40px 40px;
        gap: 30px;

        > img {
          width: 240px;
          // height: 328px;
          height: 100%;
          object-fit: cover;
        }

        .intro {
          .name {
            font-size: 32px;
            font-weight: 500;
            color: rgba(31, 35, 41, 1);
          }

          .feature {
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            span {
              font-size: 18px;
              color: rgba(31, 35, 41, 0.8);
            }
          }

          .look-btn {
            margin-top: 40px;
            width: 183px;
            height: 52px;
            border-radius: 8px;
            border: 1px solid rgba(33, 150, 72, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 500;
            line-height: 32px;
            color: rgba(33, 150, 72, 1);
            cursor: pointer;
          }
        }
      }

      .special-product-right {
        width: 50%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .otherProduct {
      margin-top: 20px;
      overflow: hidden;

      .product-list {
        width: 100%;
        display: flex;
        gap: 20px;

        .product-list-item {
          width: 290px;
          height: 400px;
          box-sizing: border-box;
          border-radius: 4px;
          background: rgba(255, 255, 255, 1);
          cursor: pointer;
          flex-shrink: 0; // 不缩小
          display: flex;
          flex-direction: column;
          // justify-content: center;
          align-items: center;
          overflow: hidden;

          .pic {
            flex-shrink: 0;
            width: 100%;
            height: 239px;
            padding: 34px 40px 0 40px;
            box-sizing: border-box;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .name {
            text-align: center;
            padding: 0 40px;
            margin-top: 20px;
            font-size: 20px;
            color: rgba(31, 35, 41, 1);
          }

          .intro {
            // text-align: center;
            padding: 0 40px;
            margin-top: 8px;
            font-size: 14px;
            color: rgba(31, 35, 41, 0.6);
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }

      .btn {
        margin-top: 40px;
        display: flex;
        justify-content: center;

        button {
          width: 32px;
          height: 32px;
          border: unset;
          border-radius: 4px;
          background: rgba(255, 255, 255, 1);
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            color: rgba(51, 51, 51, 0.4);
            font-weight: 700;
          }

          &:hover {
            cursor: pointer;
            background: rgba(33, 150, 72, 1);

            i {
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
  }

  .active {
    margin-top: 0;
    opacity: 1;
    padding-top: 72px;

    transition: all 1.5s;
  }
}

</style>
