import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    homeTitles:{},
    scale: 1
  },
  getters: {
  },
  mutations: {
    saveHomeTitles(state,data){
        state.homeTitles=data
    },
    saveScale(state,data){
        state.scale=data
    }
  },
  actions: {
  },
  modules: {
  }
})
