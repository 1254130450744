import request from "@/utils/request"

// 底部版权信息
export function contactInfo(){
    return request({
        url:'/home/getBottomInfo',
        method:'POST',
    })
}

// 解决方案
export function solutionList(){
    return request({
        url:'/system/solution/list',
        method:'GET',
    })
}

// 产品
export function productList(params){
    return request({
        url:'/system/product/list',
        method:'GET',
        params
    })
}

// 应用案例
export function caseList(){
    return request({
        url:'/system/case/list',
        method:'GET',
    })
}

// 新闻分类
export function newsTypes(){
    return request({
        url:'/home/getNewsTypes',
        method:'POST',
    })
}
