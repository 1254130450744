<template>
    <div class="ecosphere-container wrap">
        <div class="module-title">
            <div class="title">企业合作伙伴</div>
        </div>

        <div class="partner">
            <!-- <div class="partner-item" v-for="(item,i) in list" :key="i">{{item.partnerName}}</div> -->
            <div class="partner-item" v-for="(item,i) in list" :key="i">
                <img :src="baseURL+item.partnerPic" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import {partnerList} from "@/api/home"
import {baseURL} from '@/utils/request'

export default {
  name: '',
  data () {
    return {
        baseURL,
        list:[]
    }
  },
  created () {
  },
  mounted () {
    this.getPartnerList()
  },
  methods: {
    // 获取合作伙伴列表
    getPartnerList(){
        partnerList().then(res=>{
            console.log(res,"合作伙伴")
            this.list=res.data
            this.list.sort((a, b) => a.orderNum-b.orderNum)
        })
    },
  },
}
</script>
<style lang="less" scoped>
.ecosphere-container{
    padding: 72px 0;
    .partner{
        margin-top: 64px;
        padding: 0 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        .partner-item{
            width: 160px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            // padding: 0 16px;
            box-sizing: border-box;
            text-align: center;
            background: #fff;
            border-radius: 4px;
            overflow: hidden;
            >img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
</style>
