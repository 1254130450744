<template>
    <div class="home">
        <Banner />
    </div>
</template>
<script>
import Banner from "@/components/Banner.vue"

export default {
  name: '',
  components:{Banner},
  data () {
    return {
        
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  },
}
</script>
<style lang="less" scoped>
.home{}

</style>
