<template>
    <div class="footer-container">
        <div class="wrap content">
            <!-- 左侧信息 -->
            <div class="footer-left">
                <div class="info">
                    <div class="info-item">
                        <span class="info-item-label">联系电话：</span>
                        <span class="info-item-value">028-87077178</span>
                    </div>
                    <div class="info-item">
                        <span class="info-item-label">售后服务：</span>
                        <span class="info-item-value">13228103000</span>
                    </div>
                    <div class="info-item">
                        <span class="info-item-label">公司地址：</span>
                        <span class="info-item-value">成都市高新区ACC中航城市广场A区1001</span>
                    </div>
                    <div class="info-item">
                        <span class="info-item-label">生产基地：</span>
                        <span class="info-item-value">成都市蒲江县工业大道2号</span>
                    </div>

                </div>
            </div>

            <!-- 右侧导航 -->
            <div class="right-nav">
                <div class="nav-item">
                    <h1>产品中心</h1>
                    <div class="nav-list">
                        <div >五情监测</div>
                        <div >太阳能杀虫灯</div>
                        <div >粘虫板/诱蝇球/诱捕器</div>
                    </div>
                </div>

                <div class="nav-item">
                    <h1>解决方案</h1>
                    <div class="nav-list">
                      <div>智慧柑橘解决方案</div>
                      <div>数字茶园解决方案</div>
                      <div>数字稻田解决方案</div>
                    </div>
                </div>

                 <div class="nav-item">
                    <h1>新闻中心</h1>
                    <div class="nav-list">
<!--                        <div>比昂新闻</div>-->
                        <div>行业资讯</div>
                        <div>专家问题</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 版权信息 -->
        <div class="wrap copyright">
          <div></div>
<!--            <div>Copyright © 2023 成都比昂科技有限公司 . All Rights Reserved</div>-->
            <div class="beian" @click="beian">蜀ICP备2022013152号-1</div>
        </div>
    </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {
        baseURL: 'http://accurate-api.bigdata5s.com'
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    beian(){
        window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank')
    }
  },
}
</script>
<style lang="less" scoped>
.footer-container{
    background: rgba(31, 45, 61, 1);
    padding: 72px 0 20px 0;
    .content{
        display: flex;
    }
    .footer-left{
        flex: 2;
        .logo{
            height: 54px;
            img{
                height: 100%;
            }
        }
        .info{
            margin-top: 32px;
            .info-item{
                font-size: 16px;
                color: rgba(255, 255, 255, 0.6);
                margin-bottom: 12px;
            }

        }
        .network-contact{
            display: flex;
            .weixin{
                margin-right: 12px;
                width: 16px;
                cursor: pointer;
                img{
                    width: 100%;
                }
            }
        }
    }

    // 右侧导航
    .right-nav{
        flex: 1.5;
        display: flex;
        justify-content: space-between;

        .nav-item{
            h1{
                font-size: 20px;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
            }
            .nav-list{
                margin-top: 24px;
                >div{
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.9);
                    margin-bottom: 12px;
                    cursor: pointer;
                }
                .more{
                    font-size: 16px;
                    color: rgba(255, 255, 255, 0.8);
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    &:hover{
                    color: rgba(255, 255, 255, 1);
                    }
                }
            }
        }
    }

    // 版权信息
    .copyright{
        margin-top: 16px;
        padding-top: 16px;
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: rgba(209, 209, 209, 0.6);
        border-top: 1px solid rgba(204, 204, 204, 0.1);
        .beian{
            cursor: pointer;
            &:hover{
                color: rgba(209, 209, 209, 1);
            }
        }
    }
}

</style>

<style lang="less">
.el-popover{
    .QR-code{
        display: flex;
        .qr-code-item{
            display: flex;
            flex-direction: column;
            margin-right: 16px;
            align-items: center;
            img{
                width: 56px;
            }
            span{
                margin-top: 6px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }

}
</style>
