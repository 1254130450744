<template>
    <div class="header">
        <div class="wrap">
            <div class="logo">
                <img src="@/assets/images/wmsg_logo.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {
    }
  },
  methods: {
  },
}
</script>
<style lang="less" scoped>
.header{
    // width: 100%;
    width: 1920px;
    max-width: 1920px;
    height: 92px;
    background: rgba(255, 255, 255, 1);
    position: fixed;
    z-index: 999;
    // top: 0;
    // left: 0;
    .wrap{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo{
            height: 80px;
            cursor: pointer;
            img{
                height: 100%;
            }
        }
        .menu{
            display: flex;
            >div{
                margin-left: 30px;
                cursor: pointer;
            }

            // 菜单导航公共部分
            .dropdown {
                // margin: 0 20px;
                .dropdown-title {
                    position: relative;
                    font-size: 20px;
                    line-height: 92px;
                    // transition: all 1s ease-in-out;
                }

                .dropdown-content {
                    // 定位显示局域
                    width: 100%;
                    height: 525px;
                    background-color: #fff;
                    border-top:1px solid  rgba(237, 243, 245, 1);
                    box-shadow: 0px 16px 20px 0px rgba(34, 39, 41, 0.1);
                    visibility: hidden;
                    opacity: 1;
                    position: absolute;
                    left: 0;
                    top: 92px;
                    z-index: 99;
                    // overflow: hidden;
                    .dropdown-content-box {
                        border-radius: 4px;
                    }
                }
                .dropdown-content2{
                    height: 257px;
                }

                &.active {
                    .dropdown-title{
                        color: rgba(52, 156, 87, 1);
                        i{
                            transform: rotate(180deg);
                        }
                    }
                    .dropdown-content {  
                        visibility: visible;
                        // opacity: 1;
                    }

                    .product-menu,.dynamic-menu,.aboutUs-menu{
                        margin-top: 0;
                        opacity: 1;
                        transition: margin .6s ,opacity  0.3s;
                    }
                    
                    .solution-menu{
                        .text-left{
                            opacity: 1;
                            margin: 0;
                            transition: margin .6s ,opacity  0.3s;
                        }
                        .text-right{
                            >div{
                                opacity: 1;
                                margin: 0;
                                transition: margin .6s ,opacity  0.3s;
                            }
                        }
                    }
                }
            }

            // 产品下拉导航
            .product-menu{
                width: 1220px;
                height: 100%;
                margin: 0 auto;
                padding: 40px 0;
                box-sizing: border-box;
                display: flex;
                margin-top: -50px;
                opacity: 0.3;
                .product-left{
                    // width: 213px;
                    border-right: 1px solid  rgba(216, 224, 227, 1);
                    div{
                        padding-right: 23px;
                        color: rgba(31, 35, 41, 1);
                        font-size: 20px;
                        font-weight: 500;
                        margin-bottom: 21px; 
                        border-right: 1px solid transparent;
                        white-space: nowrap;
                    }
                     .active{
                            color: rgba(52, 156, 87, 1);
                            border-right: 1px solid rgba(52, 156, 87, 1);
                        }
                }
                .product-soft{
                    flex: 1;
                    padding-left: 30px;
                    display: flex;
                    overflow-y: auto;

                    .product-list{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        .product-list-item{
                            width: 260px;
                            height: 47px;
                            padding: 6px 8px;
                            border-radius: 4px;
                            margin-right: 20px;
                            margin-bottom: 17px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                    
                            &:hover{
                                background: rgba(52, 156, 87, .15);
                            }
                            img{
                                width: 32px;
                            }
                            .main{
                                margin-left: 7px;
                                .name{
                                    font-size: 14px;
                                    color: rgba(31, 35, 41, 1);
                                }
                                .desc{
                                    font-size: 12px;
                                    color: rgba(31, 35, 41, 0.6);
                                }
                            }
                        }
                    }
                    .new-list{
                        width: 48%;
                        display: flex;
                        .new-item{
                            flex-shrink: 0;
                            width: 248px;
                            height: 392px;
                            margin-right: 20px;
                            border-radius: 8px;
                            padding: 20px;
                            box-sizing: border-box;
                            border: 1px solid rgba(0, 0, 0, 0.1);            
                            // box-shadow: 0px 8px 24px 0px rgba(31, 35, 41, 0.1);
                            position: relative;
                            &:hover{
                                .detail-btn{
                                    color: rgba(28, 133, 232, 1);
                                    background: rgba(240, 246, 252, 1);
                                }
                            }
                            .name{
                                font-size: 16px;
                                color: rgba(31, 35, 41, 1);
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                padding: 0 50px 0 0;
                            }
                            .desc{
                                height: 17px;
                                margin-top: 2px;
                                font-size: 12px;
                                color: rgba(31, 35, 41, 0.8);
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            .new{
                                padding: 4px 8px;
                                background: rgba(255, 141, 26, 0.2);
                                color: rgba(255, 141, 26, 1);
                                font-size: 12px;
                                border-radius: 4px;
                                display: inline-block;
                                position: absolute;
                                top: 20px;
                                right: 26px;
                            }
                            .pic{
                                margin-top: 16px;
                                width: 100%;
                                height: 254px;
                                border-radius: 8px;
                                overflow: hidden;
                                img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .detail-btn{
                                margin-top: 20px;
                                display: inline-block;
                                height: 25px;
                                padding: 4px 12px;
                                box-sizing: border-box;
                                border-radius: 4px;
                                font-size: 12px;
                                color: rgba(31, 35, 41, 1);
                                span{
                                    margin-right: 7px;
                                }
                            }
                        }
                    }
                }

                .product-hard{
                    flex: 1;
                    padding-right: 26px;
                    display: flex;
                    justify-content: space-between;
                    overflow-y: auto;

                    .hard-items{
                        margin-left: 26px;
                        // height: 400px;
                        .label{
                            margin-left: 8px;
                            font-size: 18px;
                            color: rgba(52, 156, 87, 1);
                        }
                        .hard-pro{
                            margin-top: 8px;
                            width: 202px;
                            padding: 6px 8px;
                            box-sizing: border-box;
                            &:hover{
                                background: rgba(52, 156, 87, .15);
                            }
                            .name{
                                font-size: 14px;
                                color: rgba(31, 35, 41, 1);
                            }
                            .intro{
                                font-size: 12px;
                                color: rgba(31, 35, 41, 0.6);
                                white-space: nowrap; 
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }

            // 解决方案下拉导航
            .solution-menu{
                height: 100%;
                display: flex;
                background: rgba(242, 245, 250, 1);
                .left-box{
                    width: 50%;
                    background: rgba(242, 245, 250, 1);
                }
                .right-box{
                    width: 50%;
                    background: #fff;
                }

                // 内容盒子
                .text-wrap{
                    width: 1220px;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .text-left{
                    padding-top: 40px;
                    padding-right: 4%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    opacity: 0.3;
                    margin-top: -50px;
                
                    .label{
                        width: 205px;
                        font-size: 18px;
                        color: rgba(52, 156, 87, 1);
                        border-bottom: 1px solid rgba(216, 224, 227, 1);
                        padding: 5px 0;
                    }
                    .solution-list{
                        margin-top: 4px;
                        li{
                            display: flex;
                            align-items: center;
                            margin-top: 21px;
                            .serial{
                                width: 16px;
                                height: 16px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: rgba(52, 156, 87, .15);
                                border-radius: 50%;
                                color: #fff;
                                font-size: 12px;
                            }
                            .name{
                                font-size: 14px;
                                color: rgba(52, 156, 87, 1);
                                margin: 0 8px;
                                width: 200px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            i{
                                font-size: 14px;
                            }
                            &:hover{
                                .serial{
                                background: rgba(52, 156, 87, .25);
                                color: rgba(43, 130, 229, 1);
                                }
                                .name{
                                color: rgba(52, 156, 87, 1);
                                }
                                i{
                                color: rgba(52, 156, 87, 1);
                                }
                            }
                        }
                    }                   
                }
                .text-right{
                    flex: 1;
                    height: 100%;
                    padding: 40px 0 0 50px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    background: #fff;
                   
                    >div:first-child{
                        width: 570px;
                        opacity: 0.3;
                        margin-top: -50px;
                        .label{
                            font-size: 18px;
                            color: rgba(52, 156, 87, 1);
                            border-bottom: 1px solid rgba(216, 224, 227, 1);
                            padding: 5px 0;
                        }
                        .case-list{
                            margin-top: 16px;
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            .item{
                                width: 260px;
                                padding: 6px 8px;
                                border-radius: 4px;
                                margin-right: 20px;
                                margin-bottom: 17px;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                        
                                &:hover{
                                    background: rgba(52, 156, 87, .15);
                                }
                                img{
                                    width: 32px;
                                    height: 32px;
                                    object-fit: cover;
                                }
                                .main{
                                    margin-left: 7px;
                                    .name{
                                        width: 220px;
                                        font-size: 14px;
                                        color: rgba(31, 35, 41, 1);
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }
                                    .desc{
                                        font-size: 12px;
                                        color: rgba(31, 35, 41, 0.6);
                                        width: 220px;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }
                                }
                            }
                        }
                    }
                    >div:nth-child(2){   
                        width: 260px;
                        height: 392px;
                        padding: 20px;
                        box-sizing: border-box;
                        border-radius: 8px;
                        border: 1px solid rgba(0, 0, 0, 0.1);            
                        // box-shadow: 0px 8px 24px 0px rgba(31, 35, 41, 0.1);
                        position: relative;
                        opacity: 0.3;
                        margin-top: -50px;
                        &:hover{
                            .detail-btn{
                                color: rgba(28, 133, 232, 1);
                                background: rgba(240, 246, 252, 1);
                            }
                        }
                        .name{
                            padding: 0 50px 0 0;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-size: 16px;
                            color: rgba(31, 35, 41, 1);
                        }
                        .desc{
                            margin-top: 2px;
                            font-size: 12px;
                            color: rgba(31, 35, 41, 0.8);
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .new{
                            padding: 4px 8px;
                            background: rgba(255, 141, 26, 0.2);
                            color: rgba(255, 141, 26, 1);
                            font-size: 12px;
                            border-radius: 4px;
                            display: inline-block;
                            position: absolute;
                            top: 20px;
                            right: 26px;
                        }
                        .pic{
                            margin-top: 16px;
                            width: 100%;
                            height: 254px;
                            border-radius: 8px;
                            overflow: hidden;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .detail-btn{
                            margin-top: 20px;
                            display: inline-block;
                            height: 25px;
                            padding: 4px 12px;
                            box-sizing: border-box;
                            border-radius: 4px;
                            font-size: 12px;
                            color: rgba(31, 35, 41, 1);
                            span{
                                margin-right: 7px;
                            }
                        }                     
                    }
                }
            }

            // 动态资讯菜单
            .dynamic-menu{
                width: 1220px;
                height: 100%;
                margin: 0 auto;
                padding-top: 40px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                margin-top: -50px;
                opacity: 0.3;

                .menu-item{
                    width: 250px;
                    height: 100%;
                    margin-right: 57px;
                    position: relative;
                    .label{
                        color: rgba(52, 156, 87, 1);
                        font-size: 18px;
                        padding: 5px 0;
                        border-bottom: 1px solid rgba(216, 224, 227, 1);
                    }
                    .list{
                        height: 400px;
                        overflow: hidden;
                        margin-left: -10px;
                        max-height: 350px;
                        li{
                            width: 260px;
                            padding: 8px;
                            box-sizing: border-box;
                            color: rgba(31, 35, 41, 1);
                            margin-top: 16px;
                            overflow: hidden; 
                            white-space: nowrap; 
                            text-overflow: ellipsis;
                            &:hover{
                                background: rgba(52, 156, 87, .15);
                            }
                        }
                    }
                    .look-more{
                        width: 88px;
                        padding: 4px 8px 4px 8px;
                        box-sizing: border-box;
                        text-align: center;
                        border-radius: 4px;    
                        color: rgba(52, 156, 87, 1);
                        font-weight: 500;
                        font-size: 14px;
                        position: absolute;
                        bottom: 40px;
                        &:hover{
                            background: rgba(52, 156, 87, .15);
                        }
                    }
                }

                .pic-text{
                    width: 260px;
                    height: 392px;
                    padding: 20px;
                    box-sizing: border-box;
                    border-radius: 8px;
                    border: 1px solid rgba(0, 0, 0, 0.1);            
                    // box-shadow: 0px 8px 24px 0px rgba(31, 35, 41, 0.1);
                    position: relative;
                    &:hover{
                        .detail-btn{
                            color: rgba(52, 156, 87, 1);
                            background: rgba(52, 156, 87, .15);
                        }
                    }
                    .name{
                        font-size: 16px;
                        color: rgba(31, 35, 41, 1);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding: 0 50px 0 0;
                    }
                    .desc{
                        margin-top: 2px;
                        font-size: 12px;
                        color: rgba(31, 35, 41, 0.8);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .new{
                        padding: 4px 8px;
                        background: rgba(255, 141, 26, 0.2);
                        color: rgba(255, 141, 26, 1);
                        font-size: 12px;
                        border-radius: 4px;
                        display: inline-block;
                        position: absolute;
                        top: 20px;
                        right: 26px;
                    }
                    .pic{
                        margin-top: 16px;
                        width: 100%;
                        height: 254px;
                        border-radius: 8px;
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .detail-btn{
                        margin-top: 20px;
                        display: inline-block;
                        height: 25px;
                        padding: 4px 12px;
                        box-sizing: border-box;
                        border-radius: 4px;
                        font-size: 12px;
                        color: rgba(31, 35, 41, 1);
                        span{
                            margin-right: 7px;
                        }
                    }

                }
            }

            // 关于我们
            .aboutUs-menu{
                width: 1220px;
                margin: 0 auto;
                padding-top: 34px;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                margin-top: -50px;
                opacity: 0.3;

                .aboutUs-menu-item{
                    // width: 140px;
                    flex: 0 0 140px;
                    height: 36px;
                    padding: 8px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    margin-right: 180px;
                    margin-bottom: 32px;
                    display: flex;
                    align-items: center;
                    &:hover{
                        background: rgba(52, 156, 87, .15);
                    }
                    &:nth-child(4n){
                        margin-right: auto;
                    }
                    img{
                        width: 24px;
                        height: 24px;
                    }
                    span{
                        margin-left: 2px;
                        font-size: 18px;
                        color: rgba(33, 39, 49, 1);
                    }
                }
            }
        }       
    }
}
</style>
