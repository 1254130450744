import request from '@/utils/request'

// 解决方案详情
export function solutionDetails(id){
    return request({
        url:'/system/solution/'+id,
        method:'GET',
    })
}

// 获取热门解决方案
export function hotSolution(params){
    return request({
        url:'/system/solution/list',
        method:'GET',
        params
    })
}